import React from 'react';
import styled from 'styled-components';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Table from 'components/table/Table';
import Button from 'components/forms/Button';
import Form from 'parts/form/Form';
import StickyContent from 'parts/sticky-content/StickyContent';
import VideoIframe from 'parts/video/VideoIframe';
import {
	Document,
	TextBox,
	TextLink,
	Video,
} from '../templates/blog-post/components/embed-assets';
import SanitizeHtml from './SanitizeHtml';
import ContentGridElements from 'parts/content-grid-elements/ContentGridElements';
import RelatedContent from 'parts/related-content/RelatedContent';
import Image from 'parts/image/Image';
import Miscellaneous from 'parts/miscellaneous/Miscellaneous';
import { SingleButton } from 'parts/title-and-text/Text';
import Stats from 'parts/stats/Stats';
import JobVacancies from 'parts/job-vacancies/JobVacancies';

/* Init dayjs plugin */
dayjs.locale('nb');
dayjs.extend(customParseFormat);

const GrayBox = styled.b`
	background: ${p => p.theme.colors.grey300};
	border-radius: ${p => p.theme.utils.borderRadius};
	display: block;
	padding: 30px;
	font-weight: normal;
`;

const Cursive = styled.span`
	color: ${p => p.theme.colors.grey700};
	font-size: 14px;
	line-height: 22px;
`;

const SmallText = styled.small`
	font-size: 14px !important;
	line-height: 22px !important;
`;

/**
 * Check if the value is a string
 * @param {string} value - The value to check.
 * @returns {boolean} - Returns true if the value is a string.
 **/
function isString(value) {
	return typeof value === 'string';
}

/**
 * Check if the value is a string and first character is a star
 * @param {string} str - The value to check.
 * @returns {boolean} - Returns true if the value is a string and first character is a star.
 **/
function hasLeadingStar(str) {
	return isString(str) && str.charAt(0) === '*';
}

// Check if string starts and ends with square brackets
function hasSquareBrackets(str) {
	return (
		isString(str) &&
		str.charAt(0) === '[' &&
		str.charAt(str.length - 1) === ']'
	);
}

// Remove square brackets from string
function removeSquareBrackets(str) {
	return isString(str) ? str.replace(/^\[|\]$/g, '') : str;
}

/**
 * Remove first and last star from string, if any (*text*)
 * @param {string} str - The value to remove stars from.
 * @returns {string} - Returns the string without stars.
 **/
function removeStarsChars(str) {
	return isString(str) ? str.replace(/^\*|\*$/g, '') : str;
}

/**
 * Check if the string is empty
 * @param {string} str - The value to check.
 * @returns {boolean} - Returns true if the string is empty.
 **/
function isEmptyString(str) {
	return isString(str) && str.replace(/\s/g, '').length === 0;
}

/**
 * Object with all the component-renderers for the different embedded entry types
 * @type {object}
 **/
const componentRenderers = {
	ContentfulKomponentRutenettelementer: ContentGridElements,
	ContentfulKomponentKnapp: Button,
	ContentfulKomponentSkjema: Form,
	ContentfulKomponentInnhold: TextBox,
	ContentfulKomponentRelatertInnhold: RelatedContent,
	ContentfulKomponentSkreddersydd: Miscellaneous,
	ContentfulKomponentStickyBilderOgTekst: StickyContent,
	ContentfulKomponentStatistikk: Stats,
	ContentfulKomponentLedigeStillinger: JobVacancies,
};

/**
 * Object with all the renderers for the different embedded asset types
 * @type {object}
 **/
const renderers = {
	image: (node, description, file) => (
		<Image
			image={node?.data?.target}
			alt={description}
			ariaLabel={description}
			{...node?.data?.target}
		/>
	),
	video: (node, description, file) => (
		<Video
			url={file?.url}
			description={description}
			ariaLabel={description}
		/>
	),
	application: (node, title, description, file, mimeType, fileType) => (
		<Document
			url={file?.url || ''}
			title={title || ''}
			contenttype={mimeType}
			fileName={file?.fileName}
			fileSize={file?.details?.size}
			fileExtension={fileType || ''}
		/>
	),
	text: (node, title, description, file, mimeType, fileType) => (
		<Document
			url={file?.url || ''}
			title={title || ''}
			contenttype={mimeType}
			fileName={file?.fileName}
			fileSize={file?.details?.size}
			fileExtension={fileType}
		/>
	),
};

/**
 * Define the options for the rich text renderer
 */
const ContentfulOptions = {
	renderMark: {
		[MARKS.BOLD]: text => <span style={{ fontWeight: '500' }}>{text}</span>,
	},
	renderNode: {
		[BLOCKS.LIST_ITEM]: (node, children) => {
			const UnTaggedChildren = documentToReactComponents(node, {
				renderNode: {
					[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
					[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
					[INLINES.ENTRY_HYPERLINK]: (node, children) => {
						if (!node?.data?.target?.slug && !children) return;
						const squareBrackets = hasSquareBrackets(children[0]);
						const text = squareBrackets
							? removeSquareBrackets(children[0])
							: children;
						return (
							<TextLink
								url={node?.data?.target?.slug}
								text={text || 'Les mer »'}
							/>
						);
					},
					[INLINES.ASSET_HYPERLINK]: (node, children) => {
						if (!node?.data?.target?.file && !children) return;
						return (
							<TextLink
								url={node?.data?.target?.file?.url}
								text={children || 'Les mer »'}
							/>
						);
					},
					[INLINES.EMBEDDED_ENTRY]: node => {
						//console.log(node);
						const field = node.data?.target;
						const typename = field?.__typename;

						if (typename !== 'ContentfulKomponentInnhold') {
							return null;
						}

						return <span>{field?.title}</span>;
					},
					[BLOCKS.EMBEDDED_ASSET]: node => {
						const fields = node?.data?.target;
						const title = fields?.title || '';
						const description = fields?.description || '';
						const file = fields?.file || {};
						const mimeType = file?.contentType;
						const mimeGroup = mimeType?.split('/')[0];
						const fileType = mimeType?.split('/')[1];

						if (!node?.nodeType === 'embedded-asset-block') return;

						// Check if the mime type is in the renderers object
						const Renderer = renderers[mimeGroup];

						// If the mime type is not in the renderers object, log an error
						if (!Renderer) {
							console.log('Unknown mime type: ', mimeType);
							return null;
						}

						// Render the component
						return Renderer(
							node,
							title,
							description,
							file,
							mimeType,
							fileType
						);
					},
				},
			});
			return <>{UnTaggedChildren}</>;
		},
		[BLOCKS.TABLE]: (node, children) => {
			// Count the number of cells in the first row
			const tableHeaderCellCount =
				node?.content?.[0]?.content?.length || 0;

			// Check if the first row is a header
			const isHeader = children[0]?.type === 'thead';

			// Set the size of the table based on the number of cells in the first row
			const size = tableHeaderCellCount > 5 ? 'large' : 'default';

			// Split the children into header and rows
			const Header = isHeader ? children[0] : null;
			const Rows = isHeader ? children.slice(1) : children;

			return (
				<Table size={size}>
					{Header && Header}
					<tbody>{Rows}</tbody>
				</Table>
			);
		},
		[BLOCKS.TABLE_ROW]: (node, children) => {
			const isHeader = node?.content?.every(
				node => node.nodeType === BLOCKS.TABLE_HEADER_CELL
			);

			if (isHeader) {
				return (
					<thead>
						<tr>{children}</tr>
					</thead>
				);
			}

			return <tr>{children}</tr>;
		},
		[BLOCKS.TABLE_CELL]: (_node, children) => {
			return <td>{children}</td>;
		},
		[BLOCKS.TABLE_HEADER_CELL]: (_node, children) => {
			return <td>{children}</td>;
		},

		[BLOCKS.HEADING_1]: (node, children) => (
			<h1 className="heading">{children}</h1>
		),
		[BLOCKS.HEADING_2]: (node, children) => (
			<h2 className="heading">{children}</h2>
		),
		[BLOCKS.HEADING_3]: (node, children) => (
			<h3 className="heading">{children}</h3>
		),
		[BLOCKS.HEADING_4]: (node, children) => (
			<h4 className="heading">{children}</h4>
		),
		[BLOCKS.HEADING_5]: (node, children) => (
			<h5 className="heading">{children}</h5>
		),

		[BLOCKS.PARAGRAPH]: (node, children) => {
			let string = children[0]?.toString() || '';

			if (hasLeadingStar(string)) {
				children = (
					<SmallText>{children.map(removeStarsChars)}</SmallText>
				);
			}

			if (hasLeadingStar(children[0]?.props?.children)) {
				children = <Cursive>{children[0]?.props?.children}</Cursive>;
			}

			if (string.includes('##')) {
				const textBetween = string.split('##')[1];
				children = <GrayBox>{textBetween}</GrayBox>;
			}

			const emptyFirstNodeText = node?.content?.[0]?.value === '';
			const childLength = !!children[0] && !isEmptyString(string);

			if (node?.content?.length === 0 && children?.length === 0) {
				return null;
			}

			// Remove empty paragraph
			if (
				children?.length === 1 &&
				typeof children[0] === 'string' &&
				children[0]?.length === 0
			) {
				return null;
			}

			if (isYoutubeOrVimeoLink(children[1]?.props?.src)) {
				return <>{children}</>;
			} else if (childLength && emptyFirstNodeText) {
				return <>{children}</>;
			} else {
				return <p>{children}</p>;
			}
		},
		[BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
			const field = node.data?.target;
			const typename = node.data?.target?.__typename;
			if (!field || !typename) return;

			// Check if the typename is in the componentRenderers object
			const Component = componentRenderers[typename];

			if (!Component) {
				console.log('Unknown content-type: ', typename);
				return null;
			}

			if (typename === 'ContentfulKomponentLedigeStillinger') {
				return (
					<Component
						{...field}
						settings={['Skjul tittel og intro']}
					/>
				);
			}

			// Need text as children for ContentfulKomponentKnapp
			if (typename === 'ContentfulKomponentKnapp') {
				return (
					<Component {...field} style={{ marginTop: '10px' }}>
						{field?.text}
					</Component>
				);
			}

			// Remove spacing to ContentfulKomponentSkjema
			let spacing = field?.spacing || {};
			if (typename === 'ContentfulKomponentSkjema') {
				spacing = { top: 'none', bottom: 'none' };
			}

			return <Component {...field} spacing={spacing} />;
		},
		[INLINES.EMBEDDED_ENTRY]: node => {
			//console.log(node);
			const field = node.data?.target;
			const typename = node.data?.target?.__typename;
			if (!field || !typename) return null;

			if (
				![
					'ContentfulKomponentKnapp',
					'ContentfulKomponentSkjema',
					'ContentfulKomponentSkreddersydd',
				].includes(typename)
			)
				return null;

			if (typename === 'ContentfulKomponentSkjema') {
				field.settings = ['Vis skjema som popup'];
			}

			// Only allow Mitt NTE - Nedlastingsknapper if is KomponentSkreddersydd
			if (
				typename === 'ContentfulKomponentSkreddersydd' &&
				field?.component === 'Mitt NTE - Nedlastingsknapper'
			) {
				return <Miscellaneous {...field} />;
			}

			return <SingleButton button={field} settings={field?.settings} />;
		},
		[INLINES.HYPERLINK]: (node, children) => {
			if (!node?.data?.uri) return;

			return processHyperlink(node, children);
		},
		[INLINES.ENTRY_HYPERLINK]: (node, children) => {
			const type = node?.data?.target?.__typename;
			if (node?.data?.target?.slug && children) {
				const typeToUrl = {
					ContentfulInnholdInnlegg: 'blogg',
					ContentfulInnholdStromprodukt: 'strømprodukt',
				};

				const url = typeToUrl[type]
					? getUrl(node?.data?.target?.slug, typeToUrl[type])
					: node?.data?.target?.slug;

				return <TextLink url={url} text={children || 'Les mer »'} />;
			}
			return console.log('INLINES.ENTRY_HYPERLINK Feil: ', node);
		},
		[INLINES.ASSET_HYPERLINK]: (node, children) => {
			const file = node?.data?.target?.file || {};
			return (
				<TextLink url={file.url || ''} text={children || 'Les mer »'} />
			);
		},
		[BLOCKS.EMBEDDED_ASSET]: node => {
			const fields = node?.data?.target;
			const title = fields?.title || '';
			const description = fields?.description || '';
			const file = fields?.file || {};
			const mimeType = file?.contentType;
			const mimeGroup = mimeType?.split('/')[0];
			const fileType = mimeType?.split('/')[1];

			if (!node?.nodeType === 'embedded-asset-block') return;

			// Check if the mime type is in the renderers object
			const Renderer = renderers[mimeGroup];

			// If the mime type is not in the renderers object, log an error
			if (!Renderer) {
				console.log('Unknown mime type: ', mimeType);
				return null;
			}

			// Render the component
			return Renderer(node, title, description, file, mimeType, fileType);
		},
	},
};

/**
 * Render the content from Contentful to react components
 * @param {object} data - The data object.
 * @returns {object} - Returns the content.
 **/
export default function Content({ data }) {
	if (!data) return null;
	let dataJSON = JSON.parse(data);

	return documentToReactComponents(dataJSON, ContentfulOptions);
}

/**
 * Render the content from Contentful to rich text
 * @param {object} data - The data object.
 * @returns {object} - Returns the content.
 **/
export function ContentToRichText({ data }) {
	if (!data) return null;

	if (typeof data === 'string') {
		return data;
	}

	return renderRichText(data, ContentfulOptions);
}

/**
 * Render the content from Contentful to plain text
 * @param {object} data - The data object.
 * @returns {object} - Returns the content.
 **/
export function ContentToText({ data }) {
	if (!data) return null;
	let dataJSON = JSON.parse(data);
	return documentToPlainTextString(dataJSON);
}

/**
 * Render the content from Contentful to plain text
 * @param {object} data - The data object.
 * @returns {object} - Returns the content.
 **/
export function rawContentToText(data) {
	if (!data) return null;
	let dataJSON = JSON.parse(data);
	return documentToPlainTextString(dataJSON);
}

/**
 * Process the hyperlink and return the correct component (TextLink or VideoIframe)
 * @param {object} node - The node object.
 * @param {array} children - The children array.
 * @returns {object} - Returns the correct component.
 **/
function processHyperlink(node, children) {
	if (!node?.data?.uri) return;

	const isVideoLink = isYoutubeOrVimeoLink(node?.data?.uri);
	const videoUrl = node.data.uri;
	let url = videoUrl;

	if (!isVideoLink || (children?.length > 0 && children[0] !== url)) {
		const squareBrackets = hasSquareBrackets(children[0]);
		const text = squareBrackets
			? removeSquareBrackets(children[0])
			: children[0];

		return (
			<TextLink
				url={node?.data?.uri || ''}
				text={text}
				target={squareBrackets ? '_blank' : '_self'}
			/>
		);
	}

	if (videoUrl.includes('vimeo')) {
		return <VideoIframe src={url} />;
	}

	if (videoUrl.includes('youtu.be')) {
		const youtubeId = youtubeParser(videoUrl);
		url = youtubeId ? `https://youtube.com/embed/${youtubeId}` : videoUrl;
	}

	if (videoUrl.includes('youtube.com')) {
		url = videoUrl.replace('watch?v=', 'embed/').replace('&t=1s', '');
	}

	return <VideoIframe src={url} />;
}

/**
 * Format slug based on the type, and return the correct url with or without parent path
 * @param {string} slug - The slug to format.
 * @param {string} type - The type of the slug.
 * @returns {string} - Returns the correct url.
 */
export function getUrl(slug = '', type = '') {
	if (type === 'strukturRedirect') return slug;

	const types = {
		blogg: '/blogg/',
		strom: '/strom/',
		strømprodukt: '/strom/stromavtaler/',
		strømbestill: '/strom/strombestilling/',
		bedrift: '/bedrift/',
		bedriftavtaler: '/bedrift/strom-og-energitjenester/stromavtaler/',
		landbruk: '/landbruk/',
		landbrukavtaler: '/landbruk/stromavtaler/',
		borettslag: '/borettslag/',
		borettslagavtaler: '/borettslag/stromavtaler/',
		kundeservice: '/kundeservice/',
		guide: '/guide/',
		akademiet: '/akademiet/',
		suksesshistorie: '/om-nte/karriere/bli-kjent-med-folkene/',
		lagetmitt: '/laget-mitt/',
		produkt: '/produkter/',
	};

	let output = slug;
	const firstChar = slug && slug?.charAt(0);

	if (firstChar !== '/' && !isExternalUrl(slug)) output = '/' + slug;

	if (types[type] === undefined) return output;

	return types[type] + slug;
}

/**
 * Get the correct url based on the slug and type
 * @param {string} slug - The slug of the url
 * @param {string} type - The type of the url
 * @returns {string} - Returns the correct url
 */
export function getUrlByType(slug = '', type = '') {
	if (type === 'ContentfulStrukturRedirect') {
	}

	if (type === 'ContentfulSideNormal') {
		return (slug?.charAt(0) !== '/' && '/' + slug) || slug;
	}

	const types = {
		ContentfulInnholdInnlegg: '/blogg/',
		ContentfulInnholdStromprodukt: '/strom/stromavtaler/',
		ContentfulInnholdProdukt: '/produkter/',
		ContentfulInnholdSuksesshistorie:
			'/om-nte/karriere/bli-kjent-med-folkene/',
		ContentfulSideGuide: '/akademiet/',
	};

	let output = slug;
	const firstChar = slug && slug?.charAt(0);

	if (firstChar !== '/' && !isExternalUrl(slug)) output = '/' + slug;

	if (types[type] === undefined) return output;

	return types[type] + slug;
}

/**
 * Get the category of an url based on the pathname
 * @param {string} url - The url to get the category from
 * @returns {string} - Returns the category of the url
 */
export function getUrlCategory(url) {
	if (typeof window === 'undefined' && !url) return;

	const types = [
		{ name: 'Blogg', slug: 'blogg' },
		{ name: 'Strøm', slug: 'strom' },
		{ name: 'Strøm', slug: 'mitt-nte' },
		{ name: 'Strøm', slug: 'app' },
		{ name: 'Strøm', slug: 'fjutt' },
		{ name: 'Bedrift', slug: 'bedrift' },
		{ name: 'Landbruk', slug: 'landbruk' },
		{ name: 'Borettslag', slug: 'borettslag' },
		{ name: 'Kundeservice', slug: 'kundeservice' },
		{ name: 'Akademiet', slug: 'akademiet' },
		{ name: 'Laget Mitt', slug: 'laget-mitt' },
		{ name: 'Nærmiljøet Mitt', slug: 'naermiljoet-mitt' },
		{ name: 'TV og Internett', slug: 'tv-og-internett' },
		{ name: 'Elektrikertjenester', slug: 'elektriker' },
		{ name: 'Produkter', slug: 'produkt' },
		{ name: 'Suksesshistorier', slug: 'suksesshistorie' },
		{ name: 'Samtykke Portal', slug: 'samtykke-portal' },
		{ name: 'Solceller', slug: 'solceller' },
		{ name: 'Fordelsprogram', slug: 'fordeler' },
		{ name: 'Fordelsprogram', slug: 'dine-fordeler' },
		{ name: 'Om NTE', slug: 'om-nte' },
	];

	const match = types.find(
		t =>
			window?.location?.pathname?.includes(t.slug) ||
			url?.includes(t.slug)
	);
	if (match?.name) return match?.name;

	return 'Generelt';
}

/**
 * Get power product segment path based on customer segment
 * @param {string} segment - The segment of the power product
 * @returns {string} - Returns the power product segment path
 */
export function powerproductsegmentPath(segment) {
	if (!segment) return;
	let path = '/strom';

	switch (segment) {
		case 'Bedrift':
			path = '/bedrift';
			break;
		case 'Landbruk':
			path = '/landbruk';
			break;
		case 'Borettslag':
			path = '/borettslag';
			break;
		case 'Privat':
			path = '/strom';
			break;
		default:
			path = '/strom';
			break;
	}

	return path;
}

/**
 * Get primary customer segment for power product
 * @param {array} segments - The segments of the power product
 * @returns {string} - Returns the primary segment
 */
export function powerProductPrimarySegment(segments = []) {
	if (!segments?.length > 0) return 'Privat'; // If no segments then return default

	if (
		segments.includes('Landbruk') ||
		segments.includes(['Landbruk', 'Bedrift']) ||
		segments.includes(['Landbruk', 'Bedrift', 'Privat'])
	)
		return 'Landbruk';
	if (segments.includes('Bedrift')) return 'Bedrift';
	if (segments.includes('Borettslag')) return 'Borettslag';

	return 'Privat';
}

/**
 * Format url with trailing slash based on last character
 * @param {string} url - The url to format
 * @param {boolean} last - Add trailing slash to the last character
 * @returns {string} - Returns the formatted url
 */
export function formatUrlWithTrailingSlash(url, last = true) {
	if (!url || !variableIsString(url)) return;
	if (last) {
		const lastChar = url?.charAt(url.length - 1);
		return lastChar === '/' ? url : `${url}/`;
	}
	const firstChar = url?.charAt(0);
	return firstChar === '/' ? url : `/${url}`;
}

/**
 * Get correct power path based on segment and slug
 * @param {string} segment - The segment of the power product
 * @param {string} slug - The slug of the power product
 * @returns {string} - Returns the correct power path
 */
export function getCorrectPowerPath(segment, slug) {
	if (!segment && !slug) return;

	if (typeof segment !== 'string' && segment?.length > 0) {
		segment = segment[0];
	}

	let type = '';
	switch (segment) {
		case 'Privat':
			type = 'strømprodukt';
			break;
		case 'Bedrift':
			type = 'bedriftavtaler';
			break;
		case 'Landbruk':
			type = 'landbrukavtaler';
			break;
		case 'Borettslag':
			type = 'borettslagavtaler';
			break;
		default:
			break;
	}
	return getUrl(slug, type);
}

/**
 * Check if the url is local or external and return the correct url with or without domain
 * @param {string} url - The url to check
 * @returns {string} - Returns the correct url
 */
export function getLocalOrExternalUrl(url) {
	if (!url) return;
	let link = url;

	// If Url does start with http or www set link to default url
	if (url.startsWith('http') || url.startsWith('www')) {
		link = url;
	} else {
		// if is local link and the first character is not a slash then add it in front
		if (link.charAt(0) !== '/') link = '/' + url;

		// remove domain from link
		link.replace(/.*\/\/[^]*/, '');
	}

	return link;
}

/**
 * Check if the url is an external url
 * @param {string} url - The url to check
 * @returns {boolean} - Returns true if the url is an external url
 */
export function isExternalUrl(url) {
	if (!url) return false;

	// if first character is a hash (#) then return false
	if (url.charAt(0) === '#') return false;

	// If URL starts with http, www, or contains ctfassets
	const isPotentialExternal = /^(http|www)|ctfassets/.test(url);
	if (!isPotentialExternal) return false;

	// If URL starts with localhost, nte.no (excluding selvhjelp.nte.no), nte.local:8000, or includes netlify.app
	const isInternal =
		/^(http:\/\/localhost|https:\/\/(?!selvhjelp\.)nte\.no|http:\/\/nte\.local:8000)|netlify\.app/.test(
			url
		);
	return !isInternal;
}

/**
 * Remove special characters from string
 * @param {string} str - The string to remove special characters from
 * @returns {string} - Returns the string without special characters
 */
export function removeSpecialCharacters(str) {
	if (!str) return;
	return str
		.toLowerCase()
		.replace(/[^0-9a-z-A-Z ]/g, '')
		.replace(/\s/g, '-')
		.replace(/\s+/g, '-')
		.replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
		.replace(/---/g, '-');
}

/**
 * Get telephone link from string and return the telephone link with tel:
 * @param {string} str - The telephone string
 * @returns {string} - Returns the telephone link with tel:
 */
export function getTelephoneLink(str) {
	if (!str) return;
	if (str.indexOf('tel:') !== -1) return str.replace(/\s/g, '');
	return `tel:0047${str.replace(/\s/g, '')}`;
}

/**
 * Get email link from string and return the email link with mailto:
 * @param {string} str - The email string
 * @returns {string} - Returns the email link with mailto:
 */
export function getEmailLink(str) {
	if (!str) return;
	const strippedStr = str.replace(/^mailto:([^?]+).*/, '$1'); // Remove mailto: from email
	return `mailto:${strippedStr}`;
}

/**
 * Get valid heading level based on index and if the page has a title
 * @param {number} index - The index of the heading
 * @param {boolean} alreadyHasH1Heading - If the page already has a h1 heading
 * @returns {string} - Returns the correct heading level
 */
export function getValidHeadingLevel(index, alreadyHasH1Heading) {
	return index && index === 1 && !alreadyHasH1Heading ? 'h1' : 'h2';
}

/**
 * Get the correct heading level based on the index and if the page has a title
 * @param {boolean} hidepagetitle - Hide the page title
 * @param {number} index - The index of the heading
 * @param {object} page - The page object
 * @returns {string} - Returns the correct heading level
 */
export function getHeadingLevel(hidepagetitle, index, page) {
	if (!hidepagetitle) return getValidHeadingLevel(index + 2);
	if (!!page?.innholdsblokker?.length) {
		const contentType = page?.innholdsblokker[0]?.internal?.type;

		// If is last block on page return h3
		if (index + 1 === page?.innholdsblokker?.length) {
			return 'h3';
		}

		// If is first block on page and is one of the following content types
		if (
			[
				'ContentfulKomponentInnhold',
				'ContentfulKomponentBilde',
				'ContentfulKomponentBildeOgInnhold',
			].includes(contentType) &&
			index === 0
		) {
			return getValidHeadingLevel(1);
		}
	}
	return getValidHeadingLevel(index + 1);
}

/**
 * Shorten string to a specific length, and add '...' at the end
 * @param {string} text - The text to shorten
 * @param {number} max - The max length of the string
 * @returns {string} - Returns the shortened string
 */
export function shortenString(text, max) {
	return text && text.length > max
		? text.slice(0, max).split(' ').slice(0, -1).join(' ') + ' ...'
		: text;
}

/**
 * Format price to currency with locale and currency
 * @param {number} amount - The amount to format
 * @param {string} currency - The currency to use
 * @param {string} locale - The locale to use
 * @param {boolean} showSuffix - Show the suffix 'kr'
 * @returns {string} - Returns the formatted price
 */
export function priceFormatting(
	amount,
	currency = 'NOK',
	locale = 'en-GB',
	showSuffix = true
) {
	if (typeof amount === 'undefined' || amount === null) return '';
	let output = amount;
	const n = parseInt(amount);
	output = n
		.toLocaleString(locale, {
			style: 'currency',
			currency: currency,
		})
		.replace(',', '.')
		.replace('NOK', '')
		.trim()
		.replace(/\D00(?=\D*$)/, showSuffix ? ' kr' : ' ');
	return output;
}

/**
 * Parse youtube link and return the video id
 * @param {string} url - The youtube url
 * @returns {string} - Returns the youtube video id
 */
export function youtubeParser(url) {
	var regExp =
		/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return match && match[7].length === 11 ? match[7] : false;
}

/**
 * Debounce function to delay the execution of a function
 * @param {function} func - The function to execute
 * @param {number} wait - The time to wait before executing the function
 * @param {boolean} immediate - Execute the function immediately
 * @returns {function} - Returns the debounced function
 */
export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * Format date to norwegian format
 * @param {string} date - The date to format
 * @returns {string} - Returns the formatted date
 */
export function formatDate(date) {
	if (!date) return;
	dayjs.locale('nb');
	return dayjs(date).format('D. MMMM YYYY');
}

/**
 * Calculate the down payment amount based on price and months
 * @param {number} price - The price of the product
 * @param {number} months - The amount of months
 * @param {boolean} showSuffix - Show the suffix 'kr/mnd'
 * @returns {number} - Returns the down payment amount
 */
export function calculateDownPaymentAmount(price, months, showSuffix) {
	if (!price || !months) return;

	let pricePerMonth;

	pricePerMonth = Math.round(price / months);

	if (showSuffix) pricePerMonth += ' kr/mnd';

	return pricePerMonth;
}

/**
 * Trap focus inside modal/popup-element
 * @param {object} e - The event object
 * @param {object} ref - The ref object
 * @returns {void}
 */
export function trapModalFocus(e, ref) {
	// only execute if tab is pressed
	if (e.key !== 'Tab' || !ref) return;

	// here we query all focusable elements, customize as your own need
	const focusableModalElements = ref.current.querySelectorAll(
		'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object:not([disabled]), embed, *[tabindex], *[contenteditable]'
	);

	const firstElement = focusableModalElements[0];
	const lastElement =
		focusableModalElements[focusableModalElements.length - 1];

	// if going forward by pressing tab and lastElement is active shift focus to first focusable element
	if (!e.shiftKey && document?.activeElement === lastElement) {
		firstElement.focus();
		return e.preventDefault();
	}

	// if going backward by pressing tab and firstElement is active shift focus to last focusable element
	if (e.shiftKey && document?.activeElement === firstElement) {
		lastElement.focus();
		e.preventDefault();
	}
}

/**
 * Convert text into paragraphs, split by line breaks
 * @param {string} text - The text to convert
 * @returns {object} - Returns the text as paragraphs
 */
export function textIntoParagraphs(text) {
	let output = '';
	if (!text) return;
	const lineBreaksCount = (text.match(/\n/g) || []).length;
	if (lineBreaksCount > 0) {
		text.split('\n').map(txt => (output += '<p>' + txt + '</p>'));
	} else {
		output += '<p>' + text + '</p>';
	}
	return <SanitizeHtml html={output} />;
}

/**
 * Check wether variable is string
 * @param {any} item - The item to check
 * @returns {boolean} - Returns true if item is a string
 */
export function variableIsString(item) {
	if (!item) return null;
	return typeof item === 'string' || item instanceof String;
}

/**
 * Clean the gatsby-image-urls by removing url paramters and adding https at the beginning
 * if it does not exist. Need to be used as source for meta-image (og:image).
 */
export function cleanImageUrl(url) {
	if (!url) return;

	let output = url.split(/[?#]/)[0]; // remove parameters like fit, width and height
	// If url starts with '//' then add https: before
	if (url.startsWith('//')) output = `https:${url.split(/[?#]/)[0]}`;
	if (url.startsWith('/_gatsby/')) output = `https://nte.no${url}`;

	return output;
}

/**
 * Define structure of url-params for Kundeservice
 * @param {object} element - The element object
 * @param {string} title - The title string
 * @returns {string} - Returns the url-params
 **/
export function structureKundeserviceParams(element, title) {
	if (!element) return;
	let params = '/kundeservice';

	// Get the name of first group for the hit
	let group =
		element?.group?.length > 0 && typeof element?.group[0] === 'string'
			? removeSpecialCharacters(element?.group[0])
			: '';

	// Get the name of first category for the hit
	let category =
		element?.category?.length > 0 &&
		typeof element?.category[0] === 'string'
			? removeSpecialCharacters(element?.category[0])
			: element?.title
			? removeSpecialCharacters(element.title)
			: '';

	if (group) params += `?group=${group}`;

	if (category && group) params += `&accordion=${group}-${category}`;

	if (category && group && title)
		params += `&childAccordion=${group}-${category}-${removeSpecialCharacters(
			title
		)}`;

	return params;
}

/**
 * Format phone number to norwegian format
 * @param {string} number - The phone number to format
 * @returns {string} - Returns the formatted phone number
 */
export const formatPhoneNumber = number => {
	if (!number) return '';

	// Remove country code if present and non-digit characters
	let cleaned = number.replace(/^\+47|0047|\D/g, '');

	// Remove the first two characters if they are 47 and the total length is 10 characters
	if (cleaned.startsWith('47') && cleaned.length === 10) {
		cleaned = cleaned.substring(2);
	}

	// Insert space after every two digits
	return cleaned.replace(/(\d{2})(?=\d)/g, '$1 ');
};

/**
 * Convert bytes to megabytes
 * @param {number} bytes - The bytes to convert
 * @returns {number} - Returns the bytes converted to megabytes
 */
export function convertBytesToMb(bytes) {
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	const size = bytes / Math.pow(1024, i);
	return `${Math.round(size)} MB`;
}
/**
 * Convert bytes to kilobytes
 * @param {number} bytes - The bytes to convert
 * @returns {number} - Returns the bytes converted to kilobytes
 */
export function convertBytesToKb(bytes) {
	if (bytes === 0) return '0 KB';
	const kb = bytes / 1024;
	return `${kb.toFixed(2)} KB`;
}

/**
 * Check if string is a youtube or vimeo link
 * @param {string} src - The source url
 * @returns {boolean} - Returns true if youtube or vimeo link
 **/
export function isYoutubeOrVimeoLink(src) {
	if (!src) return;
	const regExp =
		/^(?:https?:)?(?:(?:\/\/(?:(?:www\.)?(?:youtube\.com)|youtu\.be)\/.*?(?:watch\?v=|embed\/)?)?(?:[\w-]{11}(?![\w-]))|(?:\/\/vimeo\.com\/)?[\d]{9}(?![\w])).*$/;
	return regExp.test(src) === true || false;
}

/**
 * Get the correct content type
 * @param {string} type - The type of content
 * @returns {string} - Returns the correct content type
 */
export function getCorrectContentType(type) {
	if (!type) return '';

	if (type === 'ContentfulInnholdInnlegg') return 'blogg';
	if (type === 'ContentfulInnholdStromprodukt') return 'strømprodukt';
	if (type === 'ContentfulInnholdProdukt') return 'produkt';
	if (type === 'ContentfulSideGuide') return 'akademiet';
	if (type === 'ContentfulInnholdSuksesshistorie') return 'suksesshistorie';

	return '';
}

/**
 * Get image thumbnail from youtube video
 * @param {object} e - Event object
 * @returns {string} - Returns the thumbnail url
 */
export function getYoutubeImageThumbnail(e) {
	if (!e?.target?.src) return;
	const thumbnails = [
		'maxresdefault',
		'mqdefault',
		'sddefault',
		'hqdefault',
		'default',
	];
	const url = e.target.src;
	if (e.target.naturalWidth === 120 && e.target.naturalHeight === 90) {
		for (var i = 0, len = thumbnails.length - 1; i < len; i++) {
			if (url.indexOf(thumbnails[i]) > 0) {
				return url.replace(thumbnails[i], thumbnails[i + 1]);
			}
		}
	}
}

/**
 * Function to get link type (anchor, phone, email or url)
 * @param {string} url - Url to check
 * @returns {string} - Returns the type of link
 */
export function getLinkType(url) {
	if (!url || !url?.length > 0) return;

	// If first character is an # then return "anchor"
	if (url?.charAt(0) === '#') return 'anchor';

	// Regex test if is norwegian phone number then return "phone"
	if (/^((0047)?|(\+47)?|(47)?)(\d{8})$/.test(url)) return 'phone';

	// Remove mailto from email-url
	const strippedEmail = url.replace(/^mailto:([^?]+).*/, '$1');

	// Regex test if is email (optionally with ?subject parameter) then return "email"
	if (
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+(.*\?((.*=.*)(&?))+)*$/.test(
			strippedEmail
		)
	) {
		return 'email';
	}

	return 'url';
}

/**
 * Function to strip hash from string
 * @param {string} string - String to strip hash from
 * @returns {string} - String without hash
 */
export function stripHash(string) {
	if (!string) return;
	if (string?.startsWith('#')) return string?.substring(1);
	return string;
}

/**
 * Function to define settings for page
 * @param {object} pagesettings - Array of settings
 * @returns {object} - Object of settings
 */
export function getPageSettings(pagesettings) {
	if (!pagesettings || pagesettings?.length === 0) return;

	const settings = {
		transparentheader: 'false',
		hidebreadcrumbs: 'false',
		transitions: 'false',
		headertextcolor: 'black',
	};

	pagesettings.forEach(setting => {
		switch (setting) {
			case 'Transparent header':
				settings.transparentheader = 'true';
				break;

			case 'Skjul brødsmulesti':
				settings.hidebreadcrumbs = 'true';
				break;

			case 'Hvit logo og tekst i header':
			case 'Negativ logo og hvit tekst i header':
				settings.headertextcolor = 'white';
				break;

			case 'Skjul chat-knapp på siden':
				settings.hidechatbutton = 'true';
				break;

			case 'Animasjoner på komponenter':
				settings.transitions = 'true';
				break;

			default:
				break;
		}
	});

	return settings;
}

/**
 * Function to define settings for component
 * @param {object} settings - Array of settings
 * @param {object} props - Object of props
 * @param {string} headinglevel - Heading level
 * @param {object} content - Content object
 * @returns {object} - Object of settings
 **/
export function getComponentSettings({
	settings,
	props,
	headinglevel,
	content,
}) {
	if ((!settings || settings?.length === 0) && !content && !props) return;

	const output = {
		hidetitle: 'false',
		centered: 'false',
		shadow: 'false',
		table: 'false',
		hidemediaonmobile: 'false',
		captionplacement: 'hide',
		leftcolumnsticky: 'false',
	};

	// If is first component and is h1 then set isFirstBlock to true
	if (props?.componentindex && headinglevel) {
		output.isfirstblock =
			props?.componentindex === 0 && headinglevel === 'h1'
				? 'true'
				: 'false';
	}

	// If has content then check if content has table or references
	if (
		!!(content?.raw || content?.childMarkdownRemark?.html) ||
		content?.references
	) {
		output.firstelement = getFirstElementInTextContent(content);
		output.lastelement = getLastElementInTextContent(content);
		output.table = rawContentHasTable(content?.raw);
		output.relatedcontentrefs = rawContentHasReferences(
			content?.references,
			'ContentfulKomponentRelatertInnhold',
			'relatedContent'
		);
	}

	// If has relatedcontentrefs and has more than 1 child then set hastwocolumns to true
	if (output?.relatedcontentrefs?.children > 1) {
		output.hastwocolumns = 'true';
	}

	settings?.length > 0 &&
		settings?.forEach(setting => {
			switch (setting) {
				case 'Skjul tittel og intro':
				case 'Skjul tittel ovenfor tekst':
				case 'Skjul tittel ovenfor snarveiene':
				case 'Skjul tittel og intro ovenfor accordion':
				case 'Skjul tittel og introtekst ovenfor boksene':
				case 'Skjul tittel og introtekst ovenfor innholdet':
				case 'Skjul tittel og introtekst ovenfor referanser':
				case 'Skjul tittel og introtekst ovenfor produktene':
					output.hidetitle = 'true';
					break;

				case 'Midtstilt tekst':
				case 'Midtstilt':
					output.centered = 'true';
					break;

				case 'Vis den første fanen åpen som standard':
					output.firsttabopen = 'true';
					break;

				case 'Kun én tab åpen om gangen':
					output.onlyoneopen = 'true';
					break;

				case 'Plasser introtekst under accordion':
					output.textbelow = 'true';
					break;

				case 'Badge i hjørnet "Nyhet!"':
					output.badgenew = 'true';
					break;

				case 'Ikoner på punktlister og nummererte lister':
					output.checkicon = 'true';
					output.xmarkicon = 'true';
					break;

				case 'Små ikoner':
					output.tinyimgs = 'true';
					break;

				case 'Sirkulære bilder':
					output.circularimages = 'true';
					break;

				case 'Vis anførselstegn foran hver tekst':
					output.quoteicon = 'true';
					break;

				case 'Vis skjema som popup':
					output.aspopup = 'true';
					break;

				case 'Vis "Vi leverer Altibox"-logo':
					output.showaltiboxlogo = 'true';
					break;

				case 'Vis "Elproffen"-logo':
					output.showelproffenlogo = 'true';
					break;

				case 'Vis nedlastingsknapper for app':
					output.showappdownloadbtns = 'true';
					break;

				case 'Vis lenke til produktarkiv nederst':
					output.archivelink = 'true';
					break;

				case 'Vis som tabs':
					output.astabs = 'true';
					break;

				case 'Fullbredde ikke breiere enn innholdsbredde':
					output.maxwidth = 'true';
					break;

				case 'Skjul produktknapper':
					output.hidebuttons = 'true';
					break;

				case 'Venstre skal være sticky':
					output.leftcolumnsticky = 'true';
					break;

				case 'Vis beskrivelse under hvert bilde':
					output.showcaption = 'true';
					break;

				case '1 halvbredde bilde og 2 ved siden vertikalt':
					output.imagegrid = 'true';
					break;

				case 'Vis som snarveier':
					output.asshortcuts = 'true';
					break;

				case 'Vis månedlig/årlig toggle':
					output.showpricetoggle = 'true';
					break;

				case 'Fremhev standardprodukt':
					output.highlightdefaultproduct = 'true';
					break;

				case 'Hvit ikon på lukke-knapp':
					output.whiteicon = 'true';
					break;

				case 'Sticky første kolonne i tabell':
					output.stickytable = 'true';
					break;

				case 'Skjul bilde på mobilvisning':
					output.hidemediaonmobile = 'true';
					break;

				case 'Tilfeldig utvalg':
					output.randomize = 'true';
					break;

				case 'Skygge bak innhold':
					output.shadow = 'true';
					break;

				case 'Fullbredde':
					output.fullwidth = 'true';
					break;

				case 'Vis knapp som ren tekst':
					output.linkelement = 'text';
					break;

				case 'Vis lenker som knapper':
					output.linkelement = 'button';
					break;

				case 'Neste komponent skal flyte over':
					output.nextcomponentfloat = 'true';
					break;

				case 'Skjul tilbudsknapper og rabattkoder på Fordelsprogram':
					output.hideofferbuttons = 'true';
					break;

				case 'Skjul komponenten hvis ingen ledige stillinger':
					output.hideifnovacancies = 'true';
					break;

				case 'Midtstilt seksjon':
					output.centeredsection = 'true';
					break;

				default:
					break;
			}
		});

	// Background color
	if (props?.bgColor) {
		output.bg = getCorrectColor(props?.bgColor);
	}

	// Image crop / aspect ratio
	const imageCropMap = {
		'Beskjær til 1:1': '1:1',
		'Beskjær til 16:10': '16:10',
		'Ikke beskjær': 'none',
	};

	let defaultRatio = '16:10';

	// Set default ratio based on component type
	switch (props?.internal?.type) {
		case 'ContentfulKomponentBildeOgInnhold': {
			if (props?.type?.includes('Fullbredde') && 'fullwidth') {
				defaultRatio = 'none';
			} else {
				defaultRatio = '1:1';
			}
			break;
		}

		case 'ContentfulKomponentToppbanner':
			defaultRatio = '1:1';
			break;

		case 'ContentfulKomponentPersonEr':
			defaultRatio = '1:1';
			break;

		case 'ContentfulKomponentRutenettelementer':
			defaultRatio = 'Ikke beskjær';
			break;

		default:
			defaultRatio = '16:10';
			break;
	}

	// If is an asset and has no type then set default ratio to none
	if (!props?.internal?.type && props?.__typename === 'ContentfulAsset') {
		defaultRatio = 'none';
	}

	// Set image crop
	output.imagecrop = props?.imagecrop
		? imageCropMap[props?.imagecrop]
		: defaultRatio;

	// Image caption placement
	if (props?.imageTextPlacement && props?.imagetext) {
		switch (props?.imageTextPlacement) {
			case 'Under bildet':
				output.captionplacement = 'below';
				break;

			case 'Nederst over bildet':
				output.captionplacement = 'on-top';
				break;

			default:
				output.captionplacement = 'hide';
				break;
		}
	}

	// Return the settings object
	return output;
}

/**
 * Get color theme variable based on color name
 * @param {string} color - The color name
 * @returns {string} - The color theme variable
 */
export function getCorrectColor(color) {
	let colors = {
		'NTE Blå 100': 'blue100',
		'NTE Blå 800': 'blue800',
		'NTE Grønn 200': 'green200',
		'NTE Korall 100': 'coral100',
		'NTE Grå 900': 'grey900',
		Transparent: 'transparent',

		// Extra for pillar-pages chapters
		'NTE Blå 400': 'blue400',
		'NTE Blå 600': 'blue600',
		'NTE Grønn 500': 'green500',
		'NTE Grønn 700': 'green700',
		'NTE Korall 300': 'coral300',
		'NTE Grå 700': 'grey700',
		'NTE Sjøgrønn': 'seaGreen',
	};
	return colors[color] || colors['NTE Blå 100'];
}

/**
 * Get all images from a post, both from contentblocks and references in raw content
 * @param {Object} post - The post object
 * @returns {Array} - The array of images
 */
export function getPostImages(post) {
	const postReferences = post?.content?.references || [];
	const contentBlocks = post?.contentblocks || [];
	const firstIsScrollyTelling = firstBlockIsScrollyTelling(post);
	const mainImage = !firstIsScrollyTelling && post?.image;

	const imageReferences = [...contentBlocks, ...postReferences];

	if (!mainImage && !imageReferences?.length > 0) return;

	const images = [];

	imageReferences.forEach(ref => {
		if (!ref || !ref.__typename) return;

		if (
			[
				'ContentfulKomponentStickyBilderOgTekst',
				'ContentfulAsset',
				'ContentfulKomponentBilde',
			].includes(ref.__typename)
		) {
			images.push(ref);
		}

		if (
			ref.__typename === 'ContentfulKomponentBildeOgInnhold' &&
			ref?.imageposition?.includes('Halvdel')
		) {
			if (ref?.image) {
				images.push({
					image: { ...ref?.image },
					imagetext: ref?.imagetext,
				});
			}
		}

		if (ref.__typename === 'ContentfulKomponentInnhold') {
			if (ref?.content?.references?.length > 0) {
				ref?.content?.references
					?.filter(ref => ref?.__typename === 'ContentfulAsset')
					.forEach(img =>
						images.push({
							image: { ...img },
							imagetext: null,
						})
					);
			}
		}
	});

	const flattenedImages = images
		.map(img => {
			if (img?.image)
				return {
					...img?.image,
					file: img?.image?.file,
					imagetext: img?.imagetext,
				};
			if (!img?.images?.length > 0) {
				return { ...img, file: img?.file };
			}
			return img.images.map(el => {
				return { ...el, file: el?.file };
			});
		})
		.flat();

	if (mainImage)
		flattenedImages.unshift({ ...mainImage, file: mainImage?.file });

	return (flattenedImages?.length > 0 && flattenedImages) || [];
}

export function firstBlockIsScrollyTelling(post) {
	if (!post?.contentblocks?.length > 0) return false;

	return (
		post?.contentblocks?.some(
			(el, i) =>
				i === 0 &&
				el?.images?.length > 0 &&
				el?.__typename === 'ContentfulKomponentScrollytelling'
		) || false
	);
}

/**
 * Returns plain text from a Contentful Rich Text fiel
 * @param {Object || String} text - The options object or string.
 */
export function outputPlainText(text) {
	if (!text) return;

	if (text?.raw) return rawContentToText(text?.raw);

	if (
		text?.childMarkdownRemark?.rawMarkdownBody &&
		variableIsString(text?.childMarkdownRemark?.rawMarkdownBody)
	) {
		return text?.childMarkdownRemark?.rawMarkdownBody;
	}

	if (variableIsString(text)) return text;

	if (variableIsString(text?.text)) return text?.text;
}

/**
 * Scroll to an HTML element on the page with smooth scrolling.
 *
 * @param {HTMLElement} el - The target HTML element to scroll to.
 * @param {number} offset - Optional. The offset from the top of the element to scroll to. Default is 98 pixels.
 */
export function scrollToElement(el, offset = 98) {
	// Check if the element and window are defined (for server-side rendering)
	if (!el || typeof window === 'undefined') return;

	// Calculate the position of the element relative to the viewport
	const elementPosition = el?.getBoundingClientRect()?.top;

	// Calculate the final scroll position, including the offset
	const offsetPosition = elementPosition + window?.scrollY - offset;

	// Scroll to the target element with smooth scrolling
	window?.scrollTo({
		top: offsetPosition,
		behavior: 'smooth',
	});
}

/**
 * Get the Page Type Based on URL Path
 * This function determines the page type based on the URL path.
 * @returns {string} The page type as a string
 */
export function getPageType() {
	if (typeof window === 'undefined') return;

	// Array of page types with their associated slugs
	const types = [
		{ type: 'fjutt', slug: '/app/fjutt' },
		{ type: 'mitt-nte', slug: '/app' },
		{ type: 'strom', slug: '/strom' },
		{ type: 'naeringsservice', slug: 'bedrift/naeringsservice' },
		{ type: 'bedrift', slug: '/bedrift' },
		{ type: 'landbruk', slug: '/landbruk' },
		{ type: 'borettslag', slug: '/borettslag' },
		{ type: 'kundeservice', slug: '/kundeservice' },
		{ type: 'bedriftsportalen', slug: '/bedriftsportalen' },
		{ type: 'akademiet', slug: '/akademiet' },
		{ type: 'laget-mitt', slug: '/laget-mitt' },
		{ type: 'naermiljoet-mitt', slug: '/naermiljoet-mitt' },
		{ type: 'elektriker-avdelinger', slug: '/elektriker/avdelinger' },
		{ type: 'elektriker', slug: '/elektriker' },
		{ type: 'produkter', slug: '/produkt' },
		{ type: 'suksesshistorie', slug: '/suksesshistorie' },
		{ type: 'samtykke-portal', slug: '/samtykke-portal' },
		{ type: 'solceller', slug: '/solceller' },
		{ type: 'om-nte', slug: '/om-nte' },
		{ type: 'blogg', slug: '/blogg' },
		{ type: 'fiberbestilling', slug: '/tv-og-internett' },
	];

	// Find the first type that matches the current pathname
	const match = types.find(t => window?.location?.pathname?.includes(t.slug));

	// Return the matched page type, or 'general' if no match is found
	return match?.type || 'general';
}

/**
 * Check if raw content contains a table and retrieve information about it.
 *
 * @param {string} raw - The raw content to analyze.
 * @returns {{ exists: boolean, columns: number }} An object indicating whether a table exists and the number of columns in the table.
 */
export function rawContentHasTable(raw) {
	// Check if the raw content is falsy (null, undefined, empty string)
	if (!raw) {
		// Return an object indicating that no table exists
		return { exists: 'false' };
	}

	// Parse the raw content into an array or use an empty array if parsing fails
	const rawContentAsArray = JSON.parse(raw) || [];

	// Find a table node in the content array based on the nodeType
	const tableNode = rawContentAsArray?.content?.find(
		node => node?.nodeType === 'table'
	);

	// If no table node is found, return an object indicating that no table exists
	if (!tableNode) {
		return { exists: 'false' };
	}

	// Determine the number of columns in the first row of the table
	const columns = tableNode?.content[0]?.content?.length;

	// Return an object indicating that a table exists and the number of columns
	return { exists: 'true', columns };
}

/**
 * Check if raw content contains a reference to a specific content type.
 * @param {Array} references - The array of references to check.
 * @param {string} contentType - The content type to look for in the references.
 * @param {string} childrenField - The name of the field containing child references.
 * @returns {object} An object indicating the number of references and the number of children in the references.
 **/
export function rawContentHasReferences(
	references,
	contentType,
	childrenField
) {
	if (!contentType || !references?.length > 0) return undefined;

	// count the number of references that match the specified content type
	const count = references.filter(
		ref => ref.__typename === contentType
	).length;

	// if the content type has child-refs, count the number of children in the references
	const childRefs = (childrenField && references[0][childrenField]) || [];

	return {
		count,
		children: childRefs?.length || 0,
	};
}

const nodeTypes = {
	document: ['html'],
	p: ['p', 'paragraph'],
	h1: ['h1', 'heading-1'],
	h2: ['h2', 'heading-2'],
	h3: ['h3', 'heading-3'],
	h4: ['h4', 'heading-4'],
	h5: ['h5', 'heading-5'],
	h6: ['h6', 'heading-6'],
	ol: ['ol', 'ordered-list'],
	ul: ['ul', 'unordered-list'],
	li: ['li', 'list-item'],
	blockquote: 'blockquote',
	hr: 'hr',
	a: ['a', 'hyperlink', 'entry-hyperlink', 'asset-hyperlink'],
	div: [
		'div',
		'embedded-entry-inline',
		'embedded-entry-block',
		'embedded-asset-block',
	],
	b: ['b', 'bold', 'strong'],
	i: ['i', 'italic'],
	u: ['u', 'underline'],
	s: ['s', 'strikethrough'],
	code: ['code'],
	span: ['span'],
};

/**
 * Get the node type of the first element in the text content.
 * @param {object} content - The content to analyze.
 * @returns {string} The type of the first element in the content.
 **/
export function getFirstElementInTextContent(content) {
	if (!content) return;

	// get the nodetype of the first element in the raw content
	if (content?.raw) {
		const rawContentAsArray = JSON.parse(content?.raw) || [];
		const firstElement = rawContentAsArray?.content?.find(
			node => node?.nodeType
		);

		// loop through the nodeTypes array to find the matching nodetype and return the key name
		const nodeType = Object.entries(nodeTypes).find(([_, value]) => {
			return Array.isArray(value)
				? value?.includes(firstElement?.nodeType)
				: value === firstElement?.nodeType;
		});

		// flatten the array and return the first element
		const flattened = nodeType?.flat();

		return (flattened?.length > 0 && flattened[0]) || '';
	}

	// get the nodetype of the first element in the html content
	if (content?.childMarkdownRemark?.html) {
		// get the nodetype of the first element in the html content
		const firstElementMatch =
			content.childMarkdownRemark.html.match(/<[^>]+>/g);
		return firstElementMatch?.[0]?.match(/<([a-z]+)[^>]*>/)?.[1] || '';
	}
}

/**
 * Get the last element in the text content.
 * @param {object} content - The content to analyze.
 * @returns {string} The type of the last element in the content.
 **/
export function getLastElementInTextContent(content) {
	if (!content) return;

	// get the nodetype of the last element in the raw content
	if (content?.raw) {
		const rawContentAsArray = JSON.parse(content?.raw) || [];

		const lastElement = rawContentAsArray?.content?.slice(-1)[0];

		// loop through the nodeTypes array to find the matching nodetype and return the key name
		const nodeType = Object.entries(nodeTypes).find(([_, value]) => {
			return Array.isArray(value)
				? value?.includes(lastElement?.nodeType)
				: value === lastElement?.nodeType;
		});

		return nodeType[0];
	}

	// get the nodetype of the last element in the html content
	if (content?.childMarkdownRemark?.html) {
		const lastElementMatch =
			content.childMarkdownRemark.html.match(/<[^>]+>/g);
		return (
			lastElementMatch?.slice(-1)[0]?.match(/<\/?([a-z]+)[^>]*>/i)?.[1] ||
			''
		);
	}
}

/**
 * Checks if the given date is a business day, i.e., a weekday (Monday to Friday).
 *
 * @param {Date|string|number} date - The date to be checked. It can be a Date object, a string representing a date, or a timestamp.
 * @returns {boolean} Returns true if the input date is a business day (Monday to Friday), and false otherwise.
 */
export function isBusinessDay(date) {
	if (!date) return;
	const workingWeekdays = [1, 2, 3, 4, 5];
	const weekday = dayjs(date).day();
	if (workingWeekdays.includes(weekday)) return true;

	return false;
}

/**
 * Adds a specified number of business days to the given start date and returns the resulting date.
 * Business days are considered weekdays (Monday to Friday) excluding weekends.
 *
 * @param {Date|string|number} startDate - The starting date to which business days will be added.
 *   It can be a Date object, a string representing a date, or a timestamp.
 * @param {number} number - The number of business days to be added to the start date.
 * @returns {Date} Returns the resulting date after adding the specified number of business days to the start date.
 */
export function addBusinessDays(startDate, number) {
	if (!startDate || !number) return;

	// Use Array.from to create an array of length 'number', then reduce to add business days
	return Array.from({ length: number }).reduce(date => {
		date = dayjs(date).add(1, 'day');

		// If the current day is Saturday, skip to Monday (add 2 days)
		if (dayjs(date).day() === 6) {
			date = dayjs(date).add(2, 'day');
		}
		// If the current day is Sunday, skip to Monday (add 1 day)
		else if (dayjs(date).day() === 0) {
			date = dayjs(date).add(1, 'day');
		}

		return date;
	}, startDate);
}

/**
 * Checks if a given birthdate corresponds to an individual who is legally of age (18 years or older).
 *
 * @param {string} birthdate - The birthdate string to validate (in any valid date format).
 * @returns {boolean} Returns true if the individual is 18 years or older, otherwise false.
 */
export function isLegalAge(birthdate) {
	if (!birthdate) return false;

	return dayjs().diff(dayjs(birthdate, 'DD/MM/YYYY'), 'year') >= 18;
}

/**
 * Checks if a given date string is valid based on the specified format.
 *
 * @param {string} date - The date string to validate.
 * @param {string} [format='DD/MM/YYYY'] - The format of the input date string.
 * @returns {boolean} Returns true if the input date string is valid according to the specified format, otherwise false.
 */
export function isDateValid(date, format = 'DD/MM/YYYY') {
	return dayjs(date, format, true).isValid();
}

/**
 * Determines text color based on the background color to ensure readability and accessibility.
 *
 * @param {string} bg - The background color for which the text color needs to be determined.
 * @param {string} [box=false] - Optional. Indicates whether the background has a white box on top of the background-color. Default is false.
 * @returns {Object} - An object containing color values for different text elements (stickTitle, title, text, link).
 */
export function getTextColorBasedOnBg(bg, box = 'false') {
	let colors = {
		stickTitle: 'blue600',
		title: 'grey900',
		text: 'grey900',
		link: 'blue600',
		linkHover: 'blue800',
	};

	if (!bg || box === 'true') return colors;

	//console.log(bg);

	switch (bg) {
		// Dark blue and grey backgrounds
		case 'black':
		case 'blue800':
		case 'blue900':
		case 'blue600':
		case 'grey800':
		case 'grey900':
			colors.stickTitle = 'blue200';
			colors.title = 'white';
			colors.text = 'white';
			colors.link = 'white';
			colors.linkHover = 'blue200';
			break;

		// Dark green backgrounds
		case 'green700':
			colors.stickTitle = 'green200';
			colors.title = 'white';
			colors.text = 'white';
			colors.link = 'white';
			colors.linkHover = 'green200';
			break;

		// Light coral backgrounds
		case 'coral100':
		case 'coral300':
			colors.stickTitle = 'grey900';
			colors.title = 'grey900';
			colors.text = 'grey900';
			colors.link = 'grey900';
			colors.linkHover = 'grey900';
			break;

		// Lighter backgrounds
		case 'green200':
		case 'green300':
		case 'green500':
			colors.stickTitle = 'grey900';
			break;

		// Pillar-page main page hero
		case 'seaGreen':
			colors.stickTitle = 'white';
			colors.title = 'white';
			colors.text = 'white';
			colors.link = 'white';
			colors.linkHover = 'blue200';
			break;

		default:
			break;
	}

	return colors;
}

/**
 * Handles the click event to download the file from the provided URL.
 *
 * @param {string} url - The URL of the file to be downloaded.
 * @param {string} title - The title of the file to be used as the downloaded file's name.
 * @returns {void}
 */
export async function downloadFileOnClick(url, file, title) {
	let hasClicked = false;

	if (!(url || file) || hasClicked) return;

	try {
		hasClicked = true;

		// If the file is provided, create a URL and download the file
		if (file) {
			const alink = document.createElement('a');
			alink.href = file;
			alink.download = title || file?.name;
			alink.click();
			return;
		}

		// Otherwise, fetch the file from the URL and download it
		await fetch(url).then(response => {
			response.blob().then(blob => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				const alink = document.createElement('a');
				alink.href = fileURL;
				alink.download = title || url;
				alink.click();
			});
		});
	} catch (error) {
		console.log(error);
		return false;
	}
}

/**
 * Creates a CSV file from the provided data and returns a URL to the file.
 * @param {Array} data - The data to be included in the CSV file.
 * @param {Array} columns - The columns to be included in the CSV file.
 * @returns {string} The URL of the created CSV file.
 **/
export function createCsvFile(data, columns) {
	if (!data || !data?.length > 0) return;
	const header = columns.join(',') + '\n';
	const rows = data
		.map(row => columns.map(column => `"${row[column]}"`).join(','))
		.join('\n');
	const csv = header + rows;
	const blob = new Blob([csv], { type: 'text/csv' });
	return URL.createObjectURL(blob);
}
